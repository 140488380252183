import React from 'react';
import Card, { CardContent } from '../../common/Card';
import ClosedAccountIcon from '../style/icons/closed_account.svg';
import styles from './closeaccount.module.scss';
import Head from '../../common/Head';

const CloseAccount = () => {
  return (
    <React.Fragment>
      <Head title="Account is Closed" />
      <Card title="Account is Closed!">
        <>
          <CardContent>
            <img src={ClosedAccountIcon} alt="Account closed icon" className={styles.closedAccountIcon} />
          </CardContent>
          <CardContent>
            <p className={styles.content}>
              Thank you for choosing Bitnimbus.io as your data streaming partner, hope to see you soon.
            </p>
          </CardContent>
        </>
      </Card>
    </React.Fragment>
  );
};

export default CloseAccount;
