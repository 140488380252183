import { Helmet } from 'react-helmet';

interface HeadProps {
    title: string;
    description?: string;
}

const Head = (props: HeadProps) => {
    return (
        <Helmet>
            <title>
                {props.title} - Bitnimbus.io
            </title>
            <meta name="description" content={props.description} />
        </Helmet>
    )
}

export default Head;
