import { IBlock } from '../../../framework/IBlock';
import { Message } from '../../../framework/Message';
import { BlockComponent } from '../../../framework/BlockComponent';
import { message as MESSAGE } from 'antd';
import axios, { AxiosError } from 'axios';
import { Country, State, City } from 'country-state-city';
import FormValidation from '../../../framework/FormValidation';
import { BillingInfoSchema } from '../../../schema/user';
import { FormEvent } from 'react';

export const configJSON = require('../../config');

export interface Props {
  history: any;
  location: any;
  match: any;
}

interface S {
  first_name: any;
  last_name: any;
  company_name: any;
  address_line_1: string;
  address_line_2: string;
  country: any;
  city: any;
  state: any;
  postal_code: any;
  countriesList: any;
  userData: any;
  loader: any;
  errorText: { [key: string]: string };
}

interface SS {
  id: any;
}

export default class BillingInfoController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiLoginAccount: string = '';

  constructor(props: Props) {
    super(props);
    this.state = this.getEmptyState();
    this.onHandleSubmitBillingInfo = this.onHandleSubmitBillingInfo.bind(this);
  }

  async componentDidMount() {
    super.componentDidMount();
    const countries = Country.getAllCountries();
    const { act_token } = this.props.match.params;
    this.getUserDataByACT(act_token);
    this.setState({
      countriesList: countries.map((country) => ({
        name: country.name,
        code: country.isoCode,
      })),
    });
  }

  getEmptyState = () => ({
    first_name: '',
    last_name: '',
    company_name: '',
    address_line_1: '',
    address_line_2: '',
    country: '',
    city: '',
    state: '',
    postal_code: '',
    landmark: '',
    countriesList: [],
    userData: null,
    isChecked: false,
    loader: false,
    errorText: {},
  });

  getCountryCodeFromPhoneNumber = (phone: string) => {
    // TODO: Need to make it more generic by parsing the phone number. To keep things simple, we validate phone number with US and IN
    if (phone.startsWith('+1')) {
      return "US";
    } else if (phone.startsWith('+91')) {
      return "IN";
    }
  }

  getUserDataByACT = (user_act_token: string) => {
    axios({
      method: configJSON.apiPostMethod,
      url: configJSON.baseUrl + configJSON.getUserByACTtokenEndpoint,
      headers: { 'Content-Type': configJSON.formUrlenCodedContentType },
      data: { act_token: user_act_token },
    })
      .then((res) => {
        if (res.data.status === 200) {
          const country = this.getCountryCodeFromPhoneNumber(res.data.data.phone);
          this.setState({ userData: res.data.data, country });
        } else {
          this.setState({ userData: { message: 'Invalid Activation tocken' } });
        }
      })
      .catch((err) => {
        this.setState({ userData: { message: 'Invalid Activation tocken' } });
      });
  };

  handleChange = (key: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      ...this.state,
      [key]: e.target.value,
      errorText: {
        ...this.state.errorText,
        [key]: '',
      },
    });
  };

  onHandleSubmitBillingInfo(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    this.setState({
      loader: true,
    });
    const data = {
      user_id: this.state.userData.user_id,
      account_id: this.state.userData.account_id,
      username: this.state.userData.username,
      access: this.state.userData.access,
      first_name: this.state.first_name,
      last_name: this.state.last_name,
      company_name: this.state.company_name ? this.state.company_name : null,
      address_line_1: this.state.address_line_1,
      address_line_2: this.state.address_line_2,
      country: this.state.country,
      city: this.state.city,
      state: this.state.state,
      postal_code: this.state.postal_code,
    };
    const errors = FormValidation.validateForm(BillingInfoSchema, data);
    if (FormValidation.hasErrors(errors)) {
      this.setState({
        loader: false,
        errorText: {
          ...errors,
        },
      });
      return;
    }
    const { act_token } = this.props.match.params;
    axios({
      method: configJSON.apiPostMethod,
      url: configJSON.baseUrl + configJSON.createBillingDetailandVerifyUser,
      headers: { 'Content-Type': configJSON.formUrlenCodedContentType },
      data: {
        ...data,
        act_token,
      },
    })
      .then((res) => {
        this.setState({
          loader: false,
        });
        if (res.data.success == true) {
          this.setState({
            ...this.getEmptyState(),
          });
          MESSAGE.success(res.data.message, 2);
          setTimeout(() => {
            window.location.href = res.data.redirect_url;
          }, 1000);
          
        } else {
          MESSAGE.error(res.data.message, 5);
        }
      })
      .catch((err) => {
        this.setState({
          loader: false,
        });
        if (err instanceof AxiosError) {
          MESSAGE.error(
            err.response?.data.message || 'something went wrong',
            5
          );
        }
        console.log({ err });
      });
  }
}
