import React, { ReactElement, useState, useEffect } from 'react';
import styles from './timer.module.scss';

interface DisableTimerProps {
  children: ReactElement;
}

const DisableTimer = (props: DisableTimerProps) => {
  const INIT_SECONDS = 15;
  const [disabled, setDisabled] = useState<boolean>(false);
  const [seconds, setSeconds] = useState(0);
  const [resendHits, setResendHits] = useState(0);

  useEffect(() => {
    let totalSeconds = INIT_SECONDS * resendHits;
    if (totalSeconds > 0) {
      setDisabled(true);
      setSeconds(totalSeconds);
      const interval = setInterval(() => {
        totalSeconds -= 1;
        setSeconds(totalSeconds);
        if (totalSeconds === 0) {
          setDisabled(false);
          clearInterval(interval);
        }
      }, 1000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [resendHits]);

  const onClickWrapper = () => {
    setResendHits(resendHits + 1);
    props.children.props.onClick();
  };

  return (
    <div>
      {seconds > 0 ? (
        <div className={styles.timerText}>You can request for resend in {seconds} seconds</div>
      ) : (
        React.cloneElement(props.children, {
          ...props.children.props,
          disabled,
          onClick: onClickWrapper,
        })
      )}
    </div>
  );
};

export default DisableTimer;
