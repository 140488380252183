import React from 'react';
// Customizable Area Start
import OtpCheckController, { Props } from './OtpCheckController';
import OtpInput from 'react-otp-input';
import Logo from '../../style/logo.png';
import 'react-phone-number-input/style.css';
import styles from './otpcheck.module.scss';
import Card from '../../../common/Card';
import { Link } from 'react-router-dom';
import Button from '../../../common/Button';
import DisableTimer from '../../../common/DisableTimer';
import Head from '../../../common/Head';

export default class OtpCheck extends OtpCheckController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    const { otp, loader, phone } = this.state;
    if (this.props.location.state === undefined) {
      return (
        <div className=" my-0">
          {loader == true ? (
            <div className="screen-loader-center">
              <div className="ct_center_loader">
                <span className="loader"></span>
              </div>
            </div>
          ) : (
            <div className="ct_otp_main_div">
              <div className="ct_logo_text">
                <img
                  id="full_logo_expanded"
                  src={Logo}
                  className={styles.logo}
                />
              </div>
              <div className="ct_otp_head">
                <h4>OTP Verification</h4>
                <p>
                  Please login to verify your Account.
                  <span className="form-footer">
                    <a href="/" title="Log in" data-qa-log-in="">
                      {' '}
                      Log in
                    </a>
                  </span>
                </p>
              </div>
            </div>
          )}
        </div>
      );
    }
    return (
      <React.Fragment>
        <Head title="Verify Otp" />
        <Card title="OTP Verification">
          <div>
            <div>
              <div>
                <p>
                  Enter the OTP sent to <strong>{phone}</strong>
                </p>
              </div>
              <div className={styles.otpField}>
                <form>
                  <div className="otpContainer ct_input_flex">
                    <OtpInput
                      value={otp}
                      onChange={(e: any) => this.onHandleOtpChange(e)}
                      numInputs={4}
                      renderInput={(props: any) => <input {...props} />}
                    />
                  </div>
                </form>
              </div>
              <div>
                <p>
                  Didn't receive code?{' '}
                  <DisableTimer>
                    <Button
                      type="button"
                      variant="link"
                      onClick={this.handleResendOTP}
                      label="Resend"
                    />
                  </DisableTimer>
                </p>
              </div>
              <div className="text-center">
                <Button
                  type="submit"
                  onClick={this.onHandleOtpCheck}
                  label="Verify"
                  loading={loader}
                />
              </div>
            </div>
            <hr></hr>
            <div className="form-footer ct_label">
              <p>
                Not your account?{' '}
                <Link to="/" className={styles.login}>
                  {' '}
                  Log in to your account
                </Link>
              </p>
            </div>
          </div>
        </Card>
      </React.Fragment>
    );
  }
}
