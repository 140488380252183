import React, { ChangeEvent, FormEvent, useState } from 'react';
import { Link } from 'react-router-dom';

import BaseAuthForm from '../../../common/BaseAuthForm';
import { UserLogin } from '../../../types/user';
import TextField from '../../../common/TextField';
import PasswordField from '../../../common/PasswordField';
import Button from '../../../common/Button';
import styles from './login.module.scss';
import FormValidation from '../../../framework/FormValidation';
import { LoginSchema } from '../../../schema/user';
import UserController from '../../../controllers/user';
import { message } from 'antd';
import Head from '../../../common/Head';

interface LoginProps {
  history: any;
}

const Login = (props: LoginProps) => {
  const emptyState = {
    username: '',
    password: '',
  };
  const [credentials, setCredentials] = useState<UserLogin>(emptyState);
  const [errText, setErrText] = useState<{ [key: string]: string }>(emptyState);
  const [loader, setLoader] = useState<boolean>(false);

  const handleChange =
    (field: string) => (e: ChangeEvent<HTMLInputElement>) => {
      setCredentials({
        ...credentials,
        [field]: e.target.value,
      });
      setErrText({
        ...errText,
        [field]: '',
      });
    };

  const handleResult = (result: any) => {
    if (result.success) {
      message.success(result.message);
      window.location.href = result.redirect_url;
    } else if (result.message === 'verification required') {
      if (result.reason === 'phone') {
        const pushUrl = '/otp/verify';
        message.warning('Pleaes verify your mobile number');
        props.history.push({
          pathname: pushUrl,
          state: result.details,
        });
      } else if (result.reason === 'account') {
        const pushUrl = '/verifyUser/' + result.details.act_token;
        message.warning('Please provide your details');
        props.history.push(pushUrl);
      } else if (result.reason === 'password') {
        message.warning(
          'Password has not been set up on your account yet. Reset it through reset link we have sent to your email.',
          5
        );
      }
      /*const pushUrl = "/forgot/password/" + res.data.details.act_token;
                this.props.history.push(pushUrl);*/
    } else if (result.reason === 'email') {
      message.success(result.message, 5);
    }
  };

  const handleLogin = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const errors = FormValidation.validateForm(LoginSchema, credentials);
    if (FormValidation.hasErrors(errors)) {
      setErrText({
        ...errors,
      });
      return;
    }
    setLoader(true);
    try {
      const controller = new UserController();
      const result = await controller.loginUser(credentials);
      setLoader(false);
      handleResult(result);
    } catch (err) {
      setLoader(false);
      if (err instanceof Error) {
        message.error(err.message);
        return;
      }
    }
  };

  return (
    <div>
      <Head
        title="Login"
        description="Simplify your Kafka deployment with Bitnimbus.io. Access your console and manage fully managed Kafka solutions in just a few easy clicks."
      />
      <BaseAuthForm title="Login">
        <form onSubmit={handleLogin} className={styles.form}>
          <TextField
            label="Username"
            id="username"
            onChange={handleChange('username')}
            value={credentials.username}
            errorText={errText.username}
          />
          <div className={styles.passwordField}>
            <PasswordField
              password={credentials.password}
              onChange={handleChange('password')}
              errorText={errText.password}
            />
          </div>
          <div className={styles.forgotPassword}>
            <Link
              style={{
                fontSize: '1rem',
                color: '#111111',
                textDecoration: 'underline',
                marginRight: '1rem',
              }}
              to="/forgot/password"
              title="Forgot Password?"
              data-qa-forgot-password=""
            >
              Forgot password?
            </Link>
            <Link
              style={{
                fontSize: '1rem',
                color: '#111111',
                textDecoration: 'underline',
              }}
              to="/forgot/username"
              title="Forgot username?"
              data-qa-forgot-username=""
            >
              Forgot username?
            </Link>
          </div>
          <div
            className={styles.loginButton}
            data-testid="login__submit-button"
          >
            <Button
              type="submit"
              variant="contained"
              label="Log in"
              loading={loader}
            />
          </div>
          <hr />
          <div className={styles.formFooter}>
            <p>Don't have an account?</p>
            <Link to="/account/registration">
              <Button variant="outlined" onClick={() => {}} label="Sign up" />
            </Link>
          </div>
        </form>
      </BaseAuthForm>
    </div>
  );
};

export default Login;
