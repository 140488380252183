import React from "react";

import VerifiedIcon from "../../style/icons/verified.svg";
import ForgotPasswordController, { Props } from "./ForgotPasswordController";
import Card, { CardContent } from "../../../common/Card";
import PasswordField from "../../../common/PasswordField";
import styles from "../login/login.module.scss";
import { Link } from "react-router-dom";
import Button from "../../../common/Button";
import Head from "../../../common/Head";

export default class ForgotPassword extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { password, confirmPassword, loader } = this.state;

    const handleChange =
      (key: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
          ...this.state,
          [key]: e.target.value,
          errorText: {
            ...this.state.errorText,
            [key]: "",
          },
        });
      };

    const successMessage = (
      <>
          <img
            src={VerifiedIcon}
            // style={{ width: "10%", marginBottom: "1.5rem" }}
          />
        <p>
          Your password has set successfully
        </p>
        <Link to="/">
          <Button label="Continue" />
        </Link>
      </>
    );

    return (
      <React.Fragment>
        <Head title="Reset Password" />
        <Card title="Create new password for your account">
          {this.state.success ? (
            successMessage
          ) : (
            <form className={styles.form} onSubmit={this.onHandleSubmit}>
              <CardContent>
              <p>
              Please provide a secure password that meets our password
                requirement for your Bitnimbus account
              </p>
              </CardContent>
              <div>
                <div style={{ marginBottom: "1.5rem" }}>
                  <PasswordField
                    password={password}
                    required
                    onChange={handleChange("password")}
                    showInfo={true}
                    errorText={this.state.errorText.password}
                  />
                </div>
                <div style={{ marginBottom: "2rem" }}>
                  <PasswordField
                    required
                    label="Confirm Password"
                    password={confirmPassword}
                    onChange={handleChange("confirmPassword")}
                    errorText={this.state.errorText.confirmPassword}
                    showInfo={true}
                  />
                </div>
                <div>
                  <Button
                    type="submit"
                    label="Create Password"
                    loading={loader}
                  />
                </div>
              </div>
            </form>
          )}
        </Card>
      </React.Fragment>
    );
  }
}
